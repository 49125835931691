import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import SliderImg from '../images/hero.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title='404: Not found' />
      <section className='pt-6 md:py-20'>
        <div className='container mx-auto'>
          <div className='flex flex-wrap items-center px-4'>
            <div className='w-full md:w-1/2'>
              <h1 className='text-4xl lg:text-6xl font-display font-black leading-tight mb-8'>{t('error_404_title')}</h1>
              <p className='text-black'>{t('error_404_lead')}</p>
              <Link to='/' className='transition duration-300 ease-in-out button hover:opacity-75 py-4 mt-8'>
                <span>{t('error_404_button')}</span>
              </Link>
            </div>
            <div className='w-full md:w-1/2 self-end'>
              <div className='lg:pl-8 min-w-full lg:text-right my-8 overflow-hidden -mx-4 md:-mx-0'>
                <img src={SliderImg} className='inline-block max-w-none md:max-w-full transform -translate-x-1/5 md:translate-x-0' alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
